import { IconContainer } from '../../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Fig (16x16) 아이콘
 * @param props
 * @returns
 */
export function FigFileIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0Z"
        fill="#828494"
      />
      <path
        d="M11.22 6.15308C11.79 5.73378 12.16 5.06489 12.16 4.30616C12.16 3.03827 11.12 2 9.84998 2H6.15998C4.88998 2 3.84998 3.03827 3.84998 4.30616C3.84998 5.05491 4.20998 5.72379 4.76998 6.15308C4.20998 6.57238 3.84998 7.24126 3.84998 8C3.84998 8.75874 4.20998 9.41764 4.76998 9.84692C4.20998 10.2662 3.84998 10.9351 3.84998 11.6938C3.84998 12.9617 4.88998 14 6.15998 14C7.42998 14 8.46998 12.9617 8.46998 11.6938V9.83694C8.85998 10.1265 9.32998 10.3062 9.84998 10.3062C11.12 10.3062 12.16 9.26789 12.16 8C12.16 7.24126 11.79 6.57238 11.22 6.15308ZM11.23 4.30616C11.23 5.06489 10.61 5.68386 9.84998 5.68386H8.46998V2.91847H9.84998C10.61 2.91847 11.23 3.53744 11.23 4.29617V4.30616ZM4.76998 4.30616C4.76998 3.54742 5.38998 2.92845 6.14998 2.92845H7.52998V5.69384H6.14998C5.38998 5.69384 4.76998 5.07488 4.76998 4.31614V4.30616ZM4.76998 7.99002C4.76998 7.23128 5.38998 6.61231 6.14998 6.61231H7.52998V9.3777H6.14998C5.38998 9.3777 4.76998 8.75874 4.76998 8V7.99002ZM6.14998 13.0616C5.38998 13.0616 4.76998 12.4426 4.76998 11.6839C4.76998 10.9251 5.38998 10.3062 6.14998 10.3062H7.52998V11.6839C7.52998 12.4426 6.90998 13.0616 6.14998 13.0616ZM9.83998 9.3777C9.07998 9.3777 8.45998 8.75874 8.45998 8C8.45998 7.24126 9.07998 6.6223 9.83998 6.6223C10.6 6.6223 11.22 7.24126 11.22 8C11.22 8.75874 10.6 9.3777 9.83998 9.3777Z"
        fill="white"
      />
    </IconContainer>
  );
}

export default FigFileIcon;
