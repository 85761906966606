import { IconContainer } from '../../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Ppt (16x16) 아이콘
 * @param props
 * @returns
 */
export function PptIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0Z"
        fill="#EC6F31"
      />
      <path
        d="M5.75 12C5.34 12 5 11.66 5 11.25V4.75C5 4.34 5.34 4 5.75 4H8.25C9.77 4 11 5.23 11 6.75C11 8.27 9.77 9.5 8.25 9.5H6.5V11.25C6.5 11.66 6.16 12 5.75 12ZM6.5 8H8.25C8.94 8 9.5 7.44 9.5 6.75C9.5 6.06 8.94 5.5 8.25 5.5H6.5V8Z"
        fill="white"
      />
    </IconContainer>
  );
}

export default PptIcon;
