import { useEffect, useRef, useState } from 'react';
import * as Tooltip from '.';
import { clsx } from 'clsx';
import styles from './tooltip.module.css';

interface Props {
  text: string;
  tooltipText?: string;
  classname?: string;
  side?: 'bottom' | 'right' | 'top' | 'left';
  align?: 'start' | 'center' | 'end';
  sideOffset?: number;
}
export function TooltipTextEllipsis({
  text,
  tooltipText,
  classname,
  side = 'right',
  align = 'start',
  sideOffset,
}: Props) {
  const [isUsedTooltip, setIsUsedTooltip] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkTooltipRequirement = () => {
      if (!textRef.current) {
        return;
      }

      if (textRef.current.offsetWidth < textRef.current.scrollWidth) {
        setIsUsedTooltip(true);
      } else {
        setIsUsedTooltip(false);
      }
    };

    checkTooltipRequirement();

    const resizeObserver = new ResizeObserver(() => {
      checkTooltipRequirement();
    });

    if (textRef.current) {
      resizeObserver.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        resizeObserver.unobserve(textRef.current);
      }
    };
  }, []);

  return (
    <Tooltip.Root>
      <Tooltip.Trigger
        asChild
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <p ref={textRef} className={clsx(styles.TextEllipsis, classname)}>
          {text}
        </p>
      </Tooltip.Trigger>
      {isUsedTooltip && isHovered && (
        <Tooltip.Portal>
          <Tooltip.Content
            className="z-[1000]"
            side={side}
            align={align}
            sideOffset={sideOffset}
          >
            {tooltipText ? tooltipText : text}
          </Tooltip.Content>
        </Tooltip.Portal>
      )}
    </Tooltip.Root>
  );
}
export default TooltipTextEllipsis;
