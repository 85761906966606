import { IconContainer } from '../../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Source (16x16) 아이콘
 * @param props
 * @returns
 */
export function SourceFileIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0Z"
        fill="#F3BB41"
      />
      <path
        d="M5.2475 12C5.0275 12 4.7975 11.8999 4.6575 11.7098L2.1575 8.45732C1.9475 8.18711 1.9475 7.80682 2.1575 7.54663L4.6575 4.29416C4.9075 3.96391 5.3775 3.90387 5.7075 4.15406C6.0375 4.40425 6.0975 4.8746 5.8475 5.20485L3.6975 7.99697L5.8475 10.7891C6.0975 11.1193 6.0375 11.5897 5.7075 11.8399C5.5675 11.94 5.4075 12 5.2475 12Z"
        fill="white"
      />
      <path
        d="M10.7539 12C10.5939 12 10.4339 11.95 10.2939 11.8399C9.96394 11.5897 9.90394 11.1193 10.1539 10.7891L12.3039 7.99697L10.1539 5.20485C9.90394 4.8746 9.96394 4.40425 10.2939 4.15406C10.6239 3.90387 11.0939 3.96391 11.3439 4.29416L13.8439 7.54663C14.0539 7.81683 14.0539 8.19712 13.8439 8.45732L11.3439 11.7098C11.1939 11.8999 10.9739 12 10.7539 12Z"
        fill="white"
      />
    </IconContainer>
  );
}

export default SourceFileIcon;
