import * as React from 'react';
import { isValidElementType } from 'react-is';
import { ChevronDownIcon } from '@dop-ui/icons/react/dop/24/ChevronDown';
import { clsx } from 'clsx';
import * as ButtonPrimitives from './primitives';
import { fixSizeClass } from './utils';

import type { IconComponentTypes } from '@dop-ui/icons/react/dop/types';
import type { SizeTypes } from './types';

import styles from './style.module.css';

// ButtonPrimitives의 주요 타입을 re-export
export type TagTypes = ButtonPrimitives.TagTypes;
export type IconButtonSizeTypes = SizeTypes | '2xl' | 'xl' | 'xs';

type IconRenderer = (size: number) => React.ReactElement;
export type IconType = IconComponentTypes | IconRenderer;

/**
 * Button 컴포넌트 속성
 */
export interface Props extends ButtonPrimitives.RootProps {
  /** (미리 정의된) 버튼 크기 (기본값 undefined) */
  size?: IconButtonSizeTypes;
  /** 아이콘 컴포넌트 (혹은 렌더러 함수) */
  icon: IconType;
  /** 아이콘 위치 (기본값: left) */
  iconPosition?: 'left' | 'right';
  /** 토글 사용 여부 (기본값: false) */
  useToggle?: boolean;
  /** 토글 사용일 때 기본 상태 (기본값: false) */
  activated?: boolean;
  /** 토글 사용일 때 토글 변경에 대한 핸들러 */
  onToggleChange?: (isOpen: boolean) => void;
}

/**
 * IconButton 컴포넌트
 * @returns
 */
export function IconButton({
  children,
  as = 'button',
  icon,
  iconPosition = 'left',
  size,
  className,
  disabled = false,
  useToggle = false,
  activated = false,
  onToggleChange,
  onClick,
  ...props
}: React.PropsWithChildren<Props>) {
  const defaultActiveState = useToggle ? (activated ? true : false) : false;
  const [activeState, setActiveState] = React.useState(defaultActiveState);

  React.useEffect(() => {
    setActiveState(activated);
  }, [activated]);

  const toggleHandler = React.useCallback(
    (e?: React.MouseEvent<HTMLElement>) => {
      const nextState = !activeState;
      setActiveState(nextState);
      if (onToggleChange) {
        onToggleChange(nextState);
      }
      if (onClick) {
        onClick(e);
      }
    },
    [activeState, onToggleChange, onClick],
  );
  const clickHandler = useToggle ? toggleHandler : onClick;
  const btnSize = fixSizeClass<IconButtonSizeTypes>(size);

  return (
    <ButtonPrimitives.Root
      as={as}
      className={clsx(
        styles.IconButton,
        {
          [`${styles.xsmall}`]: btnSize === 'xs',
          [`${styles.small}`]: btnSize === 'sm',
          [`${styles.medium}`]: btnSize === 'md',
          [`${styles.large}`]: btnSize === 'lg',
          [`${styles.xlarge}`]: btnSize === 'xl',
          [`${styles.xxlarge}`]: btnSize === '2xl',
        },
        { [`${styles.activated}`]: useToggle && activeState },
        className,
      )}
      disabled={disabled}
      onClick={clickHandler}
      {...props}
    >
      {iconPosition === 'right' && children}
      {getIconElement(icon, getIconSize(btnSize))}
      {iconPosition === 'left' && children}
      {useToggle && (
        <ChevronDownIcon
          size={getIndicatorSize(btnSize)}
          className={styles.indicator}
        />
      )}
    </ButtonPrimitives.Root>
  );
}

function getIconSize(btnSize: IconButtonSizeTypes | undefined) {
  switch (btnSize) {
    case '2xl':
      return 60;
    case 'xl':
      return 48;
    case 'lg':
      return 36;
    case 'xs':
      return 12;
    // 기본 값 (md = 24)
    case 'md':
    case 'sm':
    default:
      return 24;
  }
}

function getIndicatorSize(btnSize: IconButtonSizeTypes | undefined) {
  switch (btnSize) {
    case '2xl':
    case 'xl':
    case 'lg':
      return 24;
    case 'sm':
    case 'xs':
      return 10;
    // 기본 값 (md = 24)
    case 'md':
    default:
      return 16;
  }
}

function getIconElement(icon: IconComponentTypes | IconRenderer, size: number) {
  if (isValidElementType(icon)) {
    const IconComp = icon as IconComponentTypes;
    return <IconComp size={size} />;
  }

  if (typeof icon === 'function') {
    const iconFunc = icon as IconRenderer;
    return iconFunc(size);
  }

  return null;
}

export default IconButton;
