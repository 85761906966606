/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  API_TOKEN_REFRESH_URL,
  HttpStatusCodes,
  ServiceErrorCodes,
} from '../../constants';
import { GatewayError } from './errors/gateway';

interface ResponseBody {
  code: string;
  error?: Error;
  message?: string;
}

const ANDROID = 'android';
const APPLE = 'go-iphone';
const BROWSER = 'BROWSER';

const getAgent = (): string => {
  if (typeof window === 'undefined') return '';
  const { userAgent } = window.navigator;
  if (/android/i.test(userAgent)) {
    return ANDROID;
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    return APPLE;
  } else {
    return BROWSER;
  }
};

export default async function reqGatewayApi(
  url: string,
  requestInit?: RequestInit,
): Promise<Response> {
  const reqFetch = async () => await fetch(url, requestInit);
  console.log('req-gateway-api.ts');
  console.log(window);

  // API Request 진행
  const response = await reqFetch();

  // 정상 응답일 경우 종료
  if (response.ok) {
    return response;
  }

  const responseBody = (await response.json()) as ResponseBody;
  const isRouteError = Boolean(
    ServiceErrorCodes[responseBody.code as keyof typeof ServiceErrorCodes],
  );

  // 토큰 만료 에러는 Gateway에서 보내줌 Gateway 에러가 아닐 시 바로 반환
  if (!isRouteError) {
    return createHttpResponse(response.status, {
      code: responseBody.code,
      message: responseBody.message,
    });
  }

  // 토큰 만료 에러일 시 갱신로직 진행 및 원래 API 로직 실행
  if (
    response.status === HttpStatusCodes.UNAUTHORIZED &&
    responseBody.code === ServiceErrorCodes['ROUTE-0006'].code
  ) {
    console.info(`[Info] 액세스 토큰이 만료되어 갱신 요청함.`);
    try {
      const resp = await refreshTokens();

      // onAuthorizeFail 와 updateAccessToken 은 gw 브릿지가 아니라 dop 브릿지 이다.
      const agent = getAgent();
      if (!resp.ok) {
        // 토큰 만료 오류를 외부에 전달한다.
        const data = (await response.json()) as unknown;
        console.log(
          `[Bridge Call] Platform > onAuthorizeFail msg: ${JSON.stringify(data)}`,
        );
        if (agent === ANDROID) {
          if ((window as any).GOMobile.onAuthorizeFail)
            (window as any).GOMobile.onAuthorizeFail(response);
        } else if (agent === APPLE) {
          (window as any).webkit?.messageHandlers?.onAuthorizeFail?.postMessage(
            {
              msg: response,
            },
          );
        }

        return createHttpResponse(HttpStatusCodes.UNAUTHORIZED, {
          code: ServiceErrorCodes['ROUTE-0006'].code,
          message: ServiceErrorCodes['ROUTE-0006'].message,
        });
      }

      console.log(
        `[Bridge Call] Platform > updateAccessToken accessToken: ${JSON.stringify('')}`,
      );
      if (agent === ANDROID) {
        if ((window as any).GOMobile.updateAccessToken)
          (window as any).GOMobile.updateAccessToken('');
      } else if (agent === APPLE) {
        (window as any).webkit?.messageHandlers?.updateAccessToken?.postMessage(
          {
            accessToken: '',
          },
        );
      }
      console.info(`[Info] 액세스 토큰 갱신이 성공하여 API 요청을 재수행함.`);
      return await reqFetch();
    } catch (error) {
      // 기타 오류로 처리
      return createHttpResponse(HttpStatusCodes.INTERNAL_SERVER_ERROR, {
        code: 'INTERNAL_SERVER_ERROR',
        error: error as Error,
      });
    }
  }

  // 다른 Gateway 에러일 시 에러처리 필요
  return createHttpResponse(response.status, {
    code: responseBody.code,
    message: responseBody.message,
    error: new GatewayError(
      responseBody.message ? responseBody.message : '',
      responseBody.code,
    ),
  });
}

// 토큰 갱신 로직
async function refreshTokens() {
  const response = await fetch(API_TOKEN_REFRESH_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
}

const createHttpResponse = (statusCode: number, body: ResponseBody) => {
  return new Response(JSON.stringify(body), {
    status: statusCode,
  });
};
