import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Change (16x16) 아이콘
 * @param props
 * @returns
 */
export function ChangeIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2566 2.05619C11.6129 1.70006 12.0961 1.5 12.5998 1.5C13.1038 1.5 13.5871 1.70019 13.9434 2.05652C14.2997 2.41285 14.4999 2.89614 14.4999 3.40007C14.4999 3.90381 14.2999 4.38693 13.9438 4.74323C13.9437 4.74336 13.9439 4.7431 13.9438 4.74323L13.0887 5.60134C13.0694 5.63168 13.0465 5.6604 13.0201 5.68689C12.994 5.71293 12.9658 5.7355 12.936 5.7546L8.35402 10.353C8.2602 10.4471 8.13275 10.5001 7.99984 10.5001H5.99984C5.72369 10.5001 5.49984 10.2762 5.49984 10.0001V8.00007C5.49984 7.86715 5.55276 7.73971 5.64692 7.64589L10.245 3.06421C10.2641 3.03428 10.2868 3.00594 10.313 2.97978C10.3396 2.95318 10.3684 2.9302 10.3989 2.91085L11.2566 2.05619C11.2567 2.05608 11.2565 2.0563 11.2566 2.05619ZM10.6742 4.04818L6.49984 8.2077V9.50007H7.79221L11.9517 5.32567L10.6742 4.04818ZM12.6576 4.6173L11.3826 3.34233L11.9634 2.76362C12.1322 2.59483 12.3611 2.5 12.5998 2.5C12.8386 2.5 13.0675 2.59483 13.2363 2.76362C13.4051 2.93242 13.4999 3.16136 13.4999 3.40007C13.4999 3.63879 13.4051 3.86772 13.2363 4.03652L13.2357 4.03715L12.6576 4.6173ZM2.70347 4.70364C3.04729 4.35982 3.51361 4.16667 3.99984 4.16667H4.6665C4.94265 4.16667 5.1665 4.39052 5.1665 4.66667C5.1665 4.94281 4.94265 5.16667 4.6665 5.16667H3.99984C3.77882 5.16667 3.56686 5.25446 3.41058 5.41074C3.2543 5.56702 3.1665 5.77899 3.1665 6V12C3.1665 12.221 3.2543 12.433 3.41058 12.5893C3.56686 12.7455 3.77882 12.8333 3.99984 12.8333H9.99984C10.2209 12.8333 10.4328 12.7455 10.5891 12.5893C10.7454 12.433 10.8332 12.221 10.8332 12V11.3333C10.8332 11.0572 11.057 10.8333 11.3332 10.8333C11.6093 10.8333 11.8332 11.0572 11.8332 11.3333V12C11.8332 12.4862 11.64 12.9525 11.2962 13.2964C10.9524 13.6402 10.4861 13.8333 9.99984 13.8333H3.99984C3.51361 13.8333 3.04729 13.6402 2.70347 13.2964C2.35966 12.9525 2.1665 12.4862 2.1665 12V6C2.1665 5.51377 2.35966 5.04745 2.70347 4.70364Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default ChangeIcon;
