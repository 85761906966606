import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 수정 (16x16) 아이콘
 * @param props
 * @returns
 */
export function EditIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <g opacity="0.4">
        <path
          d="M5.5001 15.1059L13.7035 6.90253C14.4845 6.12148 14.4845 4.85515 13.7035 4.0741L11.4381 1.80874C10.6571 1.0277 9.39076 1.0277 8.60971 1.80874L0.406321 10.0121"
          stroke="currentColor"
          strokeLinejoin="round"
          fill="currentColor"
        />
        <line
          x1="0.5"
          y1="9.60596"
          x2="0.5"
          y2="14.606"
          stroke="currentColor"
        />
        <line
          y1="15.106"
          x2="10"
          y2="15.106"
          stroke="currentColor"
          strokeLinejoin="round"
        />
        <line
          x1="8.35355"
          y1="2.2524"
          x2="13.3536"
          y2="7.2524"
          stroke="currentColor"
          strokeLinejoin="round"
        />
      </g>
    </IconContainer>
  );
}

export default EditIcon;
