import { IconContainer } from '../../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Video (16x16) 아이콘
 * @param props
 * @returns
 */
export function VideoFileIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8Z"
        fill="#A678EA"
      />
      <path
        d="M11.61 7.5L5.75001 4.08C5.37001 3.86 4.89001 4.14 4.89001 4.58V11.42C4.89001 11.86 5.37001 12.14 5.75001 11.92L11.61 8.5C11.99 8.28 11.99 7.72 11.61 7.5Z"
        fill="white"
      />
    </IconContainer>
  );
}

export default VideoFileIcon;
