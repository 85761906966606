import { IconContainer } from '../../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * File (16x16) 아이콘
 * @param props
 * @returns
 */
export function DefaultFileIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0Z"
        fill="#828494"
      />
      <path
        d="M6.75 4H3.25C2.84 4 2.5 4.34 2.5 4.75V11.25C2.5 11.66 2.84 12 3.25 12C3.66 12 4 11.66 4 11.25V8.25H6.25C6.66 8.25 7 7.91 7 7.5C7 7.09 6.66 6.75 6.25 6.75H4V5.5H6.75C7.16 5.5 7.5 5.16 7.5 4.75C7.5 4.34 7.16 4 6.75 4Z"
        fill="white"
      />
      <path
        d="M12.75 10.5H10V4.75C10 4.34 9.66 4 9.25 4C8.84 4 8.5 4.34 8.5 4.75V11.25C8.5 11.66 8.84 12 9.25 12H12.75C13.16 12 13.5 11.66 13.5 11.25C13.5 10.84 13.16 10.5 12.75 10.5Z"
        fill="white"
      />
    </IconContainer>
  );
}

export default DefaultFileIcon;
