import { IconContainer } from '../../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Txt (16x16) 아이콘
 * @param props
 * @returns
 */
export function TxtIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0Z"
        fill="#828494"
      />
      <path
        d="M12.25 5.5H3.75C3.34 5.5 3 5.16 3 4.75C3 4.34 3.34 4 3.75 4H12.25C12.66 4 13 4.34 13 4.75C13 5.16 12.66 5.5 12.25 5.5Z"
        fill="white"
      />
      <path
        d="M12.25 8.75H3.75C3.34 8.75 3 8.41 3 8C3 7.59 3.34 7.25 3.75 7.25H12.25C12.66 7.25 13 7.59 13 8C13 8.41 12.66 8.75 12.25 8.75Z"
        fill="white"
      />
      <path
        d="M9.25 12H3.75C3.34 12 3 11.66 3 11.25C3 10.84 3.34 10.5 3.75 10.5H9.25C9.66 10.5 10 10.84 10 11.25C10 11.66 9.66 12 9.25 12Z"
        fill="white"
      />
    </IconContainer>
  );
}

export default TxtIcon;
